// 页面内部操作权限细分
const pageOperation=[
    {key:0,name:'导出'},
    {key:1,name:'筛选'},
    {key:2,name:'新增'},
    {key:3,name:'模板'},
    {key:4,name:'导入'},
    {key:5,name:'编辑'},
    {key:6,name:'删除'},
    {key:7,name:'查看'},
    {key:8,name:'批量操作'},
    {key:9,name:'详情'},
    {key:10,name:'作废'},
    {key:11,name:'审批'},
    {key:12,name:'跳转'},
    {key:13,name:'入库'},
    {key:14,name:'出库'},
    {key:15,name:'打印'},
    {key:16,name:'收款'},
    {key:17,name:'新增公告'},
    {key:18,name:'公告设置'},
    {key:19,name:'重置密码'},
    {key:20,name:'强制下线'},
    {key:21,name:'表格'},
    {key:22,name:'工单操作'},
    {key:23,name:'生产'},
    {key:24,name:'质检'},
    {key:25,name:'审查'},
    {key:26,name:'切换状态'},
    {key:27,name:'指标同步'},
    {key:28,name:'指标设置'},
    {key:29,name:'折扣设置'},
    {key:30,name:'能否查看其他记录'},
    {key:31,name:'查看产品所有价格'},
    {key:32,name:'付款'},
]
export default pageOperation