export default [
  {
    key: '0', name: '首页', icon: '&#xe64f;',count:6, submenus: [
      {id:0, key: '/home',name:'首页',chart_operation:'homeData',permission:'operation_market',operation:[],is_chart:true},
    ]
  },
  {
    key: '1', name: '报表统计', icon: '&#xe601;',count:6, submenus: [
      {id:0, key: '/report/operation_market',name:'运营大盘',chart_operation:'marketData',permission:'operation_market',operation:[],is_chart:true},
      {id:1, key: '/report/sale_report',name: '销售报表',permission: 'sale_report',operation:[{key:0,name:'导出'},{key:1,name:'筛选',},{key:9,name:'详情',}] },
      {id:2, key: '/report/sample_report',name:'赠单报表',permission:"sample_report",operation:[{key:0,name:'导出'},{key:1,name:'筛选',},{key:9,name:'详情',}]},
      {id:3, key: '/report/purchase_report',name: '采购报表',permission: 'purchase_report' ,operation:[{key:1,name:'筛选',},{key:9,name:'详情',}]},
      {id:4, key: '/report/income_expense_statistics',name: '收支统计',permission: 'income_expense_statistics',operation:[] },
      {id:5, key: '/report/batch_report',name: '批次报表',permission: 'batch_report' ,operation:[{key:1,name:'筛选',}]},
      {id:6, key: '/goods_sample/sample_rate',name:'试用转化',permission:"sample_rate",operation:[]},
    ]
  },
  {
    key: '2', name: '产品管理', icon: '&#xe812;',count:7, submenus: [
      {id:0, key: '/goods/classification', name: '产品分类', permission: "classification",operation:[{key:2,name:'新增',},{key:3,name:'模板',},{key:4,name:'导入',},{key:0,name:'导出'},{key:5,name:'编辑',},{key:6,name:'删除',}] },
      {id:1, key: '/goods/unit', name: '产品单位', permission: "unit",operation:[{key:2,name:'新增',},{key:3,name:'模板',},{key:4,name:'导入',},{key:0,name:'导出'},{key:5,name:'编辑',},{key:6,name:'删除',}] },
      {id:2, key: '/goods/applicationDirection', name: '应用方向', permission:'applicationDirection',operation:[{key:2,name:'新增',},{key:3,name:'模板',},{key:4,name:'导入',},{key:0,name:'导出'},{key:5,name:'编辑',},{key:6,name:'删除',}]},
      {id:3, key: '/goods/brand', name: '品牌管理', permission: "brand",operation:[{key:2,name:'新增',},{key:3,name:'模板',},{key:4,name:'导入',},{key:0,name:'导出'},{key:5,name:'编辑',},{key:6,name:'删除',}] },
      {id:4, key: '/goods/information', name: '产品信息', permission: "information",operation:[{key:2,name:'新增',},{key:3,name:'模板',},{key:4,name:'导入',},{key:0,name:'导出'},{key:1,name:'筛选',},{key:7,name:'查看',},{key:5,name:'编辑',},{key:6,name:'删除',},{key:31,name:'查看产品所有价格',}] },
      {id:5, key: '/goods/combo', name: '产品套餐', permission: "combo",operation:[{key:2,name:'新增',},{key:1,name:'筛选',},{key:26,name:'切换状态',}] },
      {id:6, key: '/goods/temporary_warning', name: '临期预警', permission: "temporary_warning",operation:[{key:1,name:'筛选',}] },
      {id:7, key: '/goods_sample/trial_product',type:'sample', name:'试用产品',permission:"trial_product",operation:[{key:2,name:'新增',},{key:3,name:'模板',},{key:4,name:'导入',},{key:0,name:'导出'},{key:1,name:'筛选',},{key:7,name:'查看',},{key:5,name:'编辑',},{key:6,name:'删除',}]},
    ]
  },
  // {
  //   key: '3', name: '样品管理', icon: 'hdd' ,count:12, submenus: [
  //     {id:0, key: '/goods_sample/sample_purchaseRecord', name:'采购记录',permission:"sample_purchaseRecord",operation:[{key:2,name:'新增',},{key:1,name:'筛选',},{key:9,name:'详情',},{key:10,name:'作废',},{key:11,name:'审批',},{key:8,name:'批量操作',}]},
  //     {id:1, key: '/goods_sample/goods_sample', name:'赠单记录',permission:"goods_sample",operation:[{key:2,name:'新增',},{key:0,name:'导出'},{key:1,name:'筛选',},{key:9,name:'详情',},{key:10,name:'作废',},{key:11,name:'审批',},{key:8,name:'批量操作',},{key:12,name:'跳转',}]},
  //     {id:2, key: '/goods_sample/sample_inStock', name:'样品入库',permission:"sample_inStock",operation:[{key:2,name:'新增',},{key:0,name:'导出'},{key:1,name:'筛选',},{key:9,name:'详情',},{key:13,name:'入库',},{key:8,name:'批量操作',}]},
  //     {id:3, key: '/goods_sample/sample_outStock', name:'样品出库',permission:"sample_outStock",operation:[{key:2,name:'新增',},{key:0,name:'导出'},{key:1,name:'筛选',},{key:9,name:'详情',},{key:14,name:'出库',},{key:8,name:'批量操作',}]},
  //     {id:4, key: '/goods_sample/sample_inventory', name:'样品盘点',permission:"sample_inventory",operation:[{key:2,name:'新增',},{key:1,name:'筛选',},{key:9,name:'详情',},{key:10,name:'作废',}]},
  //     {id:5, key: '/goods_sample/sample_allocation', name:'样品调拨',permission:"sample_allocation",operation:[{key:2,name:'新增',},{key:1,name:'筛选',},{key:9,name:'详情',},{key:10,name:'作废',}]},
  //     {id:6, key: '/goods_sample/sample_flow', name:'样品流水',permission:"sample_flow",operation:[{key:1,name:'筛选',},{key:9,name:'详情',}]},
  //     {id:7, key: '/goods_sample/sample_stock_report', name:'样品库存',permission:"sample_stock_report",operation:[{key:0,name:'导出'},{key:1,name:'筛选',}]},
  //   ]
  // },
  {
    key: '3', name: '采购管理', icon: '&#xe651;',count:4, submenus: [
      {id:0, key: '/purchasing/purchase_record', name: '采购记录',add: { location:'/purchasing/purchase_create', type:'purchase',key:'purchaseCreate'  }, permission: "purchase_record" ,operation:[{key:2,name:'新增',},{key:0,name:'导出'},{key:1,name:'筛选',},{key:9,name:'详情',},{key:10,name:'作废',},{key:11,name:'审批',},{key:8,name:'批量操作',},{key:30,name:'能否查看其他记录'},{key:32,name:'付款'}]},
      {id:1, key: '/purchasing/return_record', name: '退货记录',add:{ location:'/purchasing/purchase_return_create' }, permission: "return_record",operation:[{key:2,name:'新增',},{key:1,name:'筛选',},{key:9,name:'详情',},{key:10,name:'作废',},{key:11,name:'审批',},{key:30,name:'能否查看其他记录'}]},
      {id:2, key: '/goods_sample/sample_purchaseRecord',type:'sample', add:{ location:'/goods_sample/sample_purchaseCreate',type:'purchase',key:'purchase_goodsSampleCreate' }, name:'样品采购记录',permission:"sample_purchaseRecord",operation:[{key:2,name:'新增',},{key:1,name:'筛选',},{key:9,name:'详情',},{key:10,name:'作废',},{key:11,name:'审批',},{key:8,name:'批量操作',},{key:30,name:'能否查看其他记录'}]},
    ],
  },
  {
    key: '4', name: '销售管理', icon: '&#xe62d;',count:5, submenus: [
      {id:0, key: '/sale/sale_record', name: '销售记录',tabType:'saleRecord',tabKey:'saleRecord', permission: "sale_record",add:{ location:'/sale/sale_create', type:'sale', key:'saleCreate' }, operation:[{key:2,name:'新增'},{key:0,name:'导出'},{key:1,name:'筛选',},{key:16,name:'收款',},{key:12,name:'跳转',},{key:9,name:'详情',},{key:10,name:'作废',},{key:11,name:'审批',},{key:8,name:'批量操作',},{key:30,name:'能否查看其他记录'}]},
      {id:1, key: '/sale/sale_managerApproval_record', name: '审销记录', permission: "sale_managerApproval_record",operation:[{key:0,name:'导出'},{key:1,name:'筛选',},{key:9,name:'详情',},{key:11,name:'审批',},{key:30,name:'能否查看其他记录'} ]},
      {id:2, key: '/sale/sale_return_record', name: '退货记录', add:{ location:'/sale/sale_return_create' }, permission: "sale_return_record" ,operation:[{key:2,name:'新增',},{key:0,name:'导出'},{key:1,name:'筛选',},{key:9,name:'详情',},{key:10,name:'作废',},{key:11,name:'审批',},{key:30,name:'能否查看其他记录'}]},
      {id:3, key: '/goods_sample/goods_sample',type:'sample',tabType:'saleRecord', tabKey:'goodsSampleRecord', add:{ location:'/goods_sample/sample_create', type:'sale', key:'goodsSampleCreate'}, name:'赠单记录',permission:"goods_sample",operation:[{key:2,name:'新增',},{key:0,name:'导出'},{key:1,name:'筛选',},{key:9,name:'详情',},{key:10,name:'作废',},{key:11,name:'审批',},{key:8,name:'批量操作',},{key:12,name:'跳转',},{key:30,name:'能否查看其他记录'}]},
    ],
  },
  {
    key: '5', name: '库存管理', icon: '&#xe64a;',count:7, submenus: [
      {id:0, key: '/warehouse/inStock', name: '入库任务', add:{ location:'/warehouse/hand_inStock_create' }, permission: "inStock",operation:[{key:2,name:'新增',},{key:0,name:'导出'},{key:1,name:'筛选',},{key:9,name:'详情',},{key:13,name:'入库',},{key:8,name:'批量操作',},{key:10,name:'作废',}] },
      {id:1, key: '/warehouse/outStock', name: '出库任务', add:{ location:'/warehouse/hand_OutStock_create' }, permission: "outStock",operation:[{key:2,name:'新增',},{key:0,name:'导出'},{key:1,name:'筛选',},{key:9,name:'详情',},{key:14,name:'出库',},{key:8,name:'批量操作',},{key:10,name:'作废',}] },
      {id:2, key: '/warehouse/inventory', name: '盘点', add:{ location:'/warehouse/inventory_create',type:'inventory', key:'inventoryCreate'}, permission: "inventory",operation:[{key:2,name:'新增',},{key:1,name:'筛选',},{key:9,name:'详情',},{key:10,name:'作废',}]},
      {id:3, key: '/warehouse/allocation', name: '调拨', add:{ location:'/warehouse/allocation_create', type:'allocation', key:'goods_allocation_create' }, permission: "allocation",operation:[{key:2,name:'新增',},{key:1,name:'筛选',},{key:9,name:'详情',},{key:10,name:'作废',}]},
      {id:4, key: '/warehouse/flow', name: '库存流水', permission: "ware_house_flow" ,operation:[{key:1,name:'筛选',},{key:9,name:'详情',}]},
      {id:5, key: '/warehouse/stock_report', name: '库存报表', permission: 'stock_report',operation:[{key:0,name:'导出'},{key:1,name:'筛选',}] },
      {id:6, key: '/warehouse/stock_warnning', name:'库存预警', permission: 'stock_warnning' ,operation:[{key:0,name:'导出'},{key:1,name:'筛选',}]},
      {id:7, key: '/goods_sample/sample_inStock',type:'sample',add:{ location:'/goods_sample/sample_hand_inStock_create' }, name:'样品入库', permission:'sample_inStock',operation:[{key:2,name:'新增',},{key:0,name:'导出'},{key:10,name:'作废',},{key:1,name:'筛选',},{key:9,name:'详情',},{key:13,name:'入库',},{key:8,name:'批量操作',}]},
      {id:8, key: '/goods_sample/sample_outStock',type:'sample',add:{ location:'/goods_sample/sample_hand_outStock_create' }, name:'样品出库', permission:'sample_outStock',operation:[{key:2,name:'新增',},{key:0,name:'导出'},{key:10,name:'作废',},{key:1,name:'筛选',},{key:9,name:'详情',},{key:14,name:'出库',},{key:8,name:'批量操作',}]},
      {id:9, key: '/goods_sample/sample_inventory',type:'sample', name:'样品盘点', add:{ location:'/goods_sample/sample_inventory_create', type:'inventory',  key:'goods_sampleinventoryCreate'},permission:"sample_inventory",operation:[{key:2,name:'新增',},{key:1,name:'筛选',},{key:9,name:'详情',},{key:10,name:'作废',}]},
      {id:10, key: '/goods_sample/sample_allocation',type:'sample', name:'样品调拨',add:{ location:'/goods_sample/sample_allocation_create', type:'allocation', key:'goods_sample_allocation_create' },permission:"sample_allocation",operation:[{key:2,name:'新增',},{key:1,name:'筛选',},{key:9,name:'详情',},{key:10,name:'作废',}]},
      {id:11, key: '/goods_sample/sample_flow',type:'sample', name:'样品流水', permission:"sample_flow",operation:[{key:1,name:'筛选',},{key:9,name:'详情',}]},
      {id:12, key: '/goods_sample/sample_stock_report', type:'sample',  name:'样品库存', permission:"sample_stock_report",operation:[{key:0,name:'导出'},{key:1,name:'筛选',}]},
    ],
  },
  {
    key: '6', name: '财务管理', icon: '&#xe646;',count:8, submenus: [
      {id:0, key: '/finance/arrears_payable', name: '应付欠款', permission: "arrears_payable",operation:[{key:1,name:'筛选',}] },
      {id:1, key: '/finance/payment', name: '付款', add:{ location:'/finance/payment_create' }, permission: "payment",operation:[{key:2,name:'新增',},{key:9,name:'详情',},{key:10,name:'作废',}] },
      {id:2, key: '/finance/arrears_receivable', name: '应收欠款', permission: "arrears_receivable" ,operation:[{key:1,name:'筛选',}]},
      {id:3, key: '/finance/collection', name: '收款', add:{ location:'/finance/collection_create' }, permission: "collection",operation:[{key:2,name:'新增',},{key:1,name:'筛选',},{key:9,name:'详情',},{key:10,name:'作废',}] },
      {id:4, key: '/finance/is_advance', name: '客户预付', permission: 'is_advance',operation:[{key:1,name:'筛选',}]},
      {id:5, key: '/finance/account_transfer', name: '账户转账', permission: "account_transfer" ,operation:[{key:2,name:'新增',},{key:10,name:'作废',}]},
      {id:6, key: '/finance/income_and_pay', name: '日常收支', permission: "income_and_pay",operation:[{key:2,name:'新增',},{key:10,name:'作废',}] },
      {id:7, key: '/finance/flow', name: '资金流水', permission: "finance_flow",operation:[{key:1,name:'筛选',},{key:9,name:'详情',}] },
    ]
  },
  {
    key: '7', name: '资产管理', icon: '&#xe626;',count:1, submenus: [
      {id:0, key: '/assets/fixed_asset', name: '固定资产', permission: "fixed_asset",operation:[{key:2,name:'新增',},{key:0,name:'导出'},{key:1,name:'筛选',},{key:5,name:'编辑',},{key:6,name:'删除',}] },
    ]
  },
  {
    key: '8', name: '客户管理', icon: '&#xe619;',count:5, submenus: [
      {id:0, key: '/client/client', name: '客户信息', permission: "client",operation:[{key:2,name:'新增',},{key:3,name:'模板',},{key:4,name:'导入',},{key:0,name:'导出'},{key:1,name:'筛选',},{key:5,name:'编辑',},{key:6,name:'删除',}] },
      {id:1, key: '/client/agent_clients', name: '经销旗下', permission: "agent_clients",operation:[{key:2,name:'新增',},{key:3,name:'模板',},{key:4,name:'导入',},{key:0,name:'导出'},{key:1,name:'筛选',},{key:5,name:'编辑',},{key:6,name:'删除',}] },
      {id:2, key: '/client/follow', name: '客户跟进', permission: "follow",operation:[{key:2,name:'新增',},{key:3,name:'模板',},{key:4,name:'导入',},{key:0,name:'导出'},{key:1,name:'筛选',},{key:5,name:'编辑',},{key:6,name:'删除',}] },
      {id:3, key: '/client/analyse', name: '客户分析', permission: "analyse",operation:[] },
      {id:4, key: '/client/business_opportunity', name:'商业机会', permission: "business_opportunity",operation:[{key:2,name:'新增',},{key:1,name:'筛选',},{key:5,name:'编辑',},{key:6,name:'删除',}]}
    ]
  },
  {
    key: '9', name: '售后管理', icon: '&#xe606;' ,count:1, submenus: [
      {id:0, key: '/after_sale/after_service', name:'售后服务',permission:"after_service",operation:[{key:2,name:'新增',},{key:1,name:'筛选',},{key:5,name:'编辑',},{key:6,name:'删除',}]}
    ]
  },
  {
    key: '10', name: '生产管理', icon: '&#xe607;',count:11, submenus: [
      {id:0, key: '/production/production_raw_material', name: '生产原料', permission: 'production_raw_material',operation:[{key:2,name:'新增',},{key:5,name:'编辑',},{key:6,name:'删除',}] },
      {id:1, key: '/production/teamsGroups', name: '生产班组', permission: "production_teamsGroups",operation:[{key:2,name:'新增',},{key:5,name:'编辑',},{key:6,name:'删除',}] },
      {id:2, key: '/production/Sop', name: '生产SOP', add:{ location:'/production/Sop_create' }, permission: "production_SOP",operation:[{key:2,name:'新增',},{key:1,name:'筛选',},{key:9,name:'详情',},{key:11,name:'审核',},{key:10,name:'作废',}] },
      {id:3, key: '/production/productionProcesses', name: '生产工序', permission: "production_productionProcesses" ,operation:[{key:2,name:'新增',},{key:5,name:'编辑',},{key:6,name:'删除',}]},
      {id:4, key: '/production/plan', name: '生产计划', permission: "production_plan",operation:[{key:2,name:'新增',},{key:1,name:'筛选',},{key:5,name:'编辑',},{key:6,name:'删除',},{key:9,name:'详情',},{key:22,name:'工单操作',}] },
      {id:5, key: '/production/qualityTesting', name: '质检工序', permission: "qualityTesting_productionProcesses",operation:[{key:2,name:'新增',},{key:5,name:'编辑',},{key:6,name:'删除',}] },
      {id:6, key: '/production/record', name: '生产记录', permission: "production_record",operation:[{key:1,name:'筛选',},{key:24,name:'质检',}] },
      {id:7, key: '/production/brand', name: '辅料品牌', permission: "production_brand" ,operation:[{key:2,name:'新增',},{key:3,name:'模板',},{key:4,name:'导入',},{key:0,name:'导出'},{key:5,name:'编辑',},{key:6,name:'删除',}]},
      {id:8, key: '/production/task', name: '生产任务', permission: "production_task",operation:[{key:1,name:'筛选',},{key:9,name:'详情',},{key:23,name:'生产',}] },
      {id:9, key: '/production/unit', name: '辅料单位', permission: "production_unit",operation:[{key:2,name:'新增',},{key:3,name:'模板',},{key:4,name:'导入',},{key:0,name:'导出'},{key:5,name:'编辑',},{key:6,name:'删除',}] },
      {id:10, key: '/production/inspection', name: '效果验证', permission: "production_sampling_inspection",operation:[{key:1,name:'筛选',},{key:25,name:'审查',}] },

    ],
  },
  // {
  //   key: '12', name: '研发管理', icon: 'project', submenus: [
  //     { key: '/research/research_manage', name: '研发管理', permission: "research_manage" },
  //   ]
  // },
  {
    key: '11', name: '数据配置', icon: '&#xe66c;',count:6, submenus: [
      {id:0, key: '/basicData/company', name: '企业信息', permission: "company",operation:[{key:2,name:'新增',},{key:1,name:'筛选',},{key:5,name:'编辑',},{key:6,name:'删除',}] },
      {id:1, key: '/basicData/department', name: '部门管理', permission: "department",operation:[{key:2,name:'新增',},{key:3,name:'模板',},{key:4,name:'导入',},{key:0,name:'导出'},{key:1,name:'筛选',},{key:5,name:'编辑',},{key:6,name:'删除',}] },
      {id:2, key: '/basicData/supplier', name: '供应商管理', permission: "supplier" ,operation:[{key:2,name:'新增',},{key:3,name:'模板',},{key:4,name:'导入',},{key:0,name:'导出'},{key:1,name:'筛选',},{key:5,name:'编辑',},{key:6,name:'删除',}]},
      {id:3, key: '/basicData/warehouse', name: '仓库管理', permission: "warehouse",operation:[{key:2,name:'新增',},{key:3,name:'模板',},{key:4,name:'导入',},{key:0,name:'导出'},{key:1,name:'筛选',},{key:5,name:'编辑',},{key:6,name:'删除',}] },
      {id:4, key: '/basicData/settlement_account', name: '结算账户', permission: "settlement_account",operation:[{key:2,name:'新增',},{key:3,name:'模板',},{key:4,name:'导入',},{key:0,name:'导出'},{key:1,name:'筛选',},{key:5,name:'编辑',},{key:6,name:'删除',}] },
      {id:5, key: '/basicData/revenue_expenditure_items', name: '收支项目', permission: "revenue_expenditure_items",operation:[{key:2,name:'新增',},{key:3,name:'模板',},{key:4,name:'导入',},{key:0,name:'导出'},{key:1,name:'筛选',},{key:5,name:'编辑',},{key:6,name:'删除',}] },
      {id:6, key: '/basicData/saler_setting', name: '销售指标', permission: "saler_setting",operation:[{key:1,name:'筛选',},{key:27,name:'指标同步',},{key:28,name:'指标设置',},{key:29,name:'折扣设置',}] },
    ]
  },
  {
    key: '12', name: '系统管理', icon: '&#xea41;',count:4, submenus: [
      {id:0, key: '/role', name: '角色管理', permission: 'role',operation:[{key:2,name:'新增',},{key:5,name:'编辑',},{key:6,name:'删除',}] },
      {id:1, key: '/account', name: '员工账号', permission: 'account',operation:[{key:2,name:'新增',},{key:17,name:'新增公告',},{key:18,name:'公告设置',},{key:1,name:'筛选',},{key:5,name:'编辑',},{key:19,name:'重置密码',},{key:6,name:'删除',},{key:20,name:'强制下线',}] },
      {id:2, key: '/config', name: '系统配置', permission: 'config',operation:[] },
      {id:3, key: '/system/user_log', name: '操作日志', permission: 'user_log',operation:[{key:1,name:'筛选',}] },
    ]
  },
]